body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-select-container {
  width: 100%;
}

.electrolux-input {
  font-family: 'Electrolux Sans';
  border: none !important;
  outline: none !important;
  font-weight: 400;
  font-size: 14.82px;
  margin-top: 5px;
  color: #040f3e;
  width: 100%;
}

.electrolux-input::placeholder {
  font-family: 'Electrolux Sans';
  color: #040f3e;
}

.electrolux-header-logo {
  margin-left: 30px;
}

.frigidaire-header-logo {
  margin-left: 30px;
}

.plan-input {
  font-family: 'Electrolux Sans';
  height: 45px;
  border: 1px solid #dfe2e6;
  outline: none !important;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #040f3e !important;
  color: white !important;
}

.PhoneInputInput {
  font-family: 'Electrolux Sans' !important;
  height: 45px !important;
  border: 1px solid #dfe2e6 !important;
  outline: none !important;
  border-radius: 5px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.bounce-img-card {
  width: 40px;
}

.location-search > div {
  width: 100%;
}

.horizontal-date-list {
  overflow-x: scroll;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
}

.hide-scroll-bar {
  height: 400px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.alert-body-color {
  color: #e74c3c;
}